import {filelist} from "./filelists/filelist20240925"
import { resultFilelist } from "./filelists/filelist20241018"
export default {
    id: 20240925,
    date: 20240925,
    uploadDate: '25.09.2024',
    title: 'Session des Kantonskirchenrates vom 18. Oktober 2024',
    shortDescriptionString: 'Sessionsprotokoll / Informationsschreiben / Ergebnisse in Kurzform / Traktanden /Sessionseinladung und Unterlagen sowie weitere Unterlagen',
    subtitle: `Der Kantonskirchenrat hielt seine ordentliche Herbstsession am 18. Oktober 2024 im Schweizerischen Jugend- und Bildungszentrum SJBZ in Einsiedeln ab.`,
    sessionDate: '18.10.2024',
    shortResults: [
        {
            name: 'sr1',
            value: 'Die “Vereinbarung zwischen der Diözese Chur und den staatskirchenrechtlichen Organisationen der Bistumskantone Uri, Schwyz, Ob- und Nidwalden, Glarus und Graubünden über die Berufseinführung im Bistum Chur (Berufseinführungsvereinbarung)” wird abgeschlossen.'
        },
        {
            name: 'sr2',
            value: 'Der Verpflichtungskredit von Fr. 76’170.-- wird zur Finanzierung des Beitrages an das Projekt Jubilate in den Jahren 2025 - 2028 wurde gutgeheissen.'
        },
        {
            name: 'sr3',
            value: 'Der Voranschlag 2025 mit einem Total des Aufwandes von Fr. 2’343’216.-- einem Defizit von Fr. 92’136.-- und dem Pro-Kopf-Beitrag an die Kantonalkirche von gesamthaft Fr. 26.-- wurde gemäss dem unveränderten Antrag des Kantonalen Kirchenvorstandes beschlossen.'
        },
        {
            name: 'sr3',
            value: 'Der Finanzausgleich 2025 wurde gemäss dem unveränderten Antrag mit einem Ausgleich des Normleistungsaufwandes von Fr. 322.70 (d.h. einem Ausgleich zu 97.5%) und einem Gesamtbudget von Fr. 1’405’043.-- beschlossen.'
        },
    ],
    resultPdfs: resultFilelist,
    Traktanden: [
        {
            name: 'tt1',
            value: 'Gebet, Begrüssung, Präsenz und Vereidigung von Mitgliedern',
        },
        {
            name: 'tt2',
            value: 'Vorstellung des Dualen Systems, der Abläufe im Kantonskirchenrat und dessen Funktionsweise',
        },
        {
            name: 'tt3',
            value: 'Beschluss über den Abschluss eines Vertrages zwischen der Diözese Chur und den staatskirchenrechtlichen Organisationen der Bistumskantone Uri, Schwyz, Ob- und Nidwalden, Glarus und Graubünden über die Berufseinführung im Bistum Chur',
        },
        {
            name: 'tt4',
            value: 'Beschluss über einen Verpflichtungskredit zugunsten des Projektes “Jubilate - Chance Kirchengesang” für die Jahre 2025 - 2028',
        },
        {
            name: 'tt5',
            value: 'Beschluss über den Voranschlag 2025',
        },
        {
            name: 'tt6',
            value: 'Beschluss über den Finanzausgleich 2025',
        },
        {
            name: 'tt7',
            value: 'Informationen der Ressortchefs und Fragestunde',
        },
        {
            name: 'tt8',
            value: 'Verschiedenes',
        },
    ],
    Unterlagen: filelist
}