export default [
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'presidentTitle',
        value: 'Präsident',
    },
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'currentPresident',
        value: 'Johannes Schwimmer (Schwyz)',
        currentPresident: {
            name: 'Johannes Schwimmer (Schwyz)',
            mail: 'johannes.schwimmer@sz.kath.ch',
            cityCode: '6438',
            cityName: 'Ibach',
            streetName: 'Studenmatt',
            streetNumber: '2',
        },
    },
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'vicePresidentTitle',
        value: 'Vizepräsident',
    },
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'currentVicePresident',
        value: 'Maria Bürgler (Illgau)',
    },
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'voiceCountersTitle',
        value: 'Stimmenzähler',
    },
    {
        target: '/Organisation/Kantonskirchenrat',
        name: 'currentVoiceCounters',
        value: 'Emil Gnos (Küssnacht) und Cyrill Villiger (Lauerz)',
        currentVoiceCounters: [
            'Emil Gnos (Küssnacht)',
            'Dr. Brigitte Fischer Züger (Altendorf)',
        ]
    },

]