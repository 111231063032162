export const resultFilelist = [
    {
        value: "Informationsschreiben",
        link: "/protocols/20241018/1_Informationsschreiben.pdf"
    },
    {
        value: "Sessionsprotokoll",
        link: "/protocols/20241018/2_Sessionsprotokoll.pdf"
    },
    {
        value: "Präsentation Duales System",
        link: "/protocols/20241018/3_Praesentation_Duales_System.pdf"
    },
    {
        value: "Formular Steuersatz 2025",
        link: "/protocols/20241018/4_Formular_Steuersatz_2025.pdf"
    },
]